<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="header-page-consulini">
          <b-row align-v="center">
            <b-col sm="1">
              <div class="icone-header-page">
                <img src="assets/images/modal_icons/1_consulta_inicial.png" />
              </div>
            </b-col>

            <b-col>
              <h1 class="titulo-header-page text-white">Consulta Inicial</h1>
              <h4 class="text-white">Inicie uma Consulta Inicial:</h4>
            </b-col>

            <b-button
              v-ripple.400="'rgba(0, 0, 0, 0)'"
              variant="outline-secondary"
              class="button-right ml-1 botao-white-outline"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
              <span class="align-middle">Excluir</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(0, 0, 0, 0)'"
              variant="outline-primary"
              class="button-right ml-1 botao-white-preenchido"
            >
              <feather-icon icon="CheckIcon" class="mr-50" />
              <span class="align-middle">Concluir Consulta</span>
            </b-button>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <h1 class="mb-2">
      <feather-icon icon="FileIcon" size="30" class="mr-50" />
      Consulta de Aplicação nº0042
    </h1>

    <b-card>
      <b-row>
        <b-col lg="6">
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar size="70px" variant="light-danger" text="CB" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">Carlos Batista</h4>
                <span class="card-text">carlosbatista@gxmail.com</span>
              </div>
              <div class="d-flex flex-wrap">
                <b-button variant="primary"> Visualizar Prontuário </b-button>
              </div>
            </div>
          </div>

          <!-- User Stats -->
          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <b-avatar variant="light-primary" rounded>
                <feather-icon icon="UserCheckIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">Dr. Cleberson Azevedo</h5>
                <small>Profissional</small>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <b-avatar variant="light-success" rounded>
                <feather-icon icon="MapPinIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">RioMar Trade Center, Sala 56</h5>
                <small>Localização</small>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <b-avatar variant="light-secondary" rounded>
                <feather-icon icon="CalendarIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">01 de março de 2022</h5>
                <small>Data da Consulta</small>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <b-avatar variant="light-secondary" rounded>
                <feather-icon icon="ClockIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">14:10h às 14:40h</h5>
                <small>Duração da Consulta</small>
              </div>
            </div>
          </div>
        </b-col>
        
      </b-row>
    </b-card>

    <b-row>
      <b-col>
        <b-card>
          <b-card-text class="mb-2 mt-2">
            <h2 class="mb-2">Preencha o pontuário do paciente.</h2>
          </b-card-text>
          <label class="mb-2 mt-2 col-form-label" for="textarea-default"
            >Demanda do paciente</label
          >
          <b-form-textarea
            id="textarea-default"
            placeholder="Inserir Detalhes"
            rows="3"
          />
          <label class="mb-2 mt-2 col-form-label" for="textarea-default"
            >Histórico do Distúrbio</label
          >
          <b-form-textarea
            id="textarea-default"
            placeholder="Inserir Detalhes"
            rows="3"
          />
          <label class="mb-2 mt-2 col-form-label" for="textarea-default"
            >Antecedentes Pessoais e Familiares</label
          >
          <b-form-textarea
            id="textarea-default"
            placeholder="Inserir Detalhes"
            rows="3"
          />
          <label class="mb-2 mt-2 col-form-label" for="textarea-default"
            >Informações Suplementares</label
          >
          <b-form-textarea
            id="textarea-default"
            placeholder="Inserir Detalhes"
            rows="3"
          />
          <label class="mb-2 mt-2 col-form-label" for="textarea-default"
            >Medicações em Uso</label
          >
          <b-form-textarea
            id="textarea-default"
            placeholder="Inserir Detalhes"
            rows="3"
          />
          <label class="mb-2 mt-2 col-form-label" for="textarea-default"
            >Observações</label
          >
          <b-form-textarea
            id="textarea-default"
            placeholder="Inserir Detalhes"
            rows="3"
          />

          <b-row class="w-100 m-0">
            <b-col col="12" class="p-0">
              <h2 class="mb-2 mt-2">
                <feather-icon icon="CheckCircleIcon" size="30" class="mr-50" />
                Anexos
              </h2>

              <DragAndDropComponent />
            </b-col>
          </b-row>
          <b-row class="button-right mt-3">
            <b-button
              class="m-1"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
            >
              Excluir
            </b-button>
            <b-button
              class="m-1"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
            >
              Editar
            </b-button>
            <b-button
              class="m-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Salvar
            </b-button>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BForm,
  BRow,
  BCol,
  BImg,
  BMedia,
  BButton,
} from 'bootstrap-vue'

import { BFormTextarea, BModal, VBModal } from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import { required, email } from '@validations'
import { codeIcon } from './code'
import { BCalendar } from 'bootstrap-vue'
import { BTime } from 'bootstrap-vue'
import { BFormTimepicker } from 'bootstrap-vue'
import DragAndDropComponent from '@/components/input/DragAndDrop.vue'
import utilsService from '@/services/utilsService'

export default {
  components: {
    BCard,
    BForm,
    BFormInput,
    BFormGroup,
    BCardText,
    BButton,
    BImg,
    BCol,
    BMedia,
    BFormTextarea,
    BModal,
    VBModal,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BCalendar,
    BTime,
    BFormTimepicker,
    DragAndDropComponent,
  },

  data() {
    return {
      UserSelecionado: '',
      TesteSelecionado: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
      email,
      codeIcon,
      TipoTeste: [
        { value: 'testcog_tipo1', text: 'Tipo de Teste 1' },
        { value: 'testcog_tipo2', text: 'Tipo de Teste 2' },
        { value: 'testcog_tipo3', text: 'Tipo de Teste 3' },
        { value: 'testcog_tipo4', text: 'Tipo de Teste 4' },
        { value: 'testcog_tipo5', text: 'Tipo de Teste 5' },
        { value: 'testcog_tipo6', text: 'Tipo de Teste 6' },
        { value: 'testcog_tipo7', text: 'Tipo de Teste 7' },
        { value: 'testcog_tipo8', text: 'Tipo de Teste 8' },
      ],

      NomeUser: [
        { value: 'user1', text: 'Jonatan Neves' },
        { value: 'user2', text: 'Artur Tavares' },
        { value: 'user3', text: 'Henrique Formiga' },
        { value: 'user4', text: 'Johnny Rodrigues' },
      ],
    }
  },
  methods: {
    ...utilsService,
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
